import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import 'intersection-observer';
import Observer from '@researchgate/react-intersection-observer';
import TransitionLink from 'gatsby-plugin-transition-link';
import Layout from '../../components/layout';
import Transition from '../../components/transition';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { InitTransition } from '../../components/init-transition';
import Style from '../../styles/services/services.module.scss';
import InitAnimation from '../../components/init-animation';

class Services extends React.Component {
  constructor() {
    super();
    this.child = React.createRef();
    this.initAnimation = new InitAnimation();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const headingServices = document.querySelector('.init_a_headingServices');
    const introServices = document.querySelector('.init_a_introServices');
    setTimeout(() => {
      this.initAnimation.heading(headingServices);
      this.initAnimation.intro(introServices);
    }, 1000);
  }

  ourServices = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isOurServices = 'OurServices';
      const headingOurServices = document.querySelector('.init_a_headingOurServices');
      const ourServicesItem = document.querySelectorAll('.init_a_ourServicesItem');
      this.initAnimation.heading(headingOurServices, isOurServices);
      this.initAnimation.list(ourServicesItem);
      unobserve();
    }
  };

  valueToGive = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isOurValueToGive = 'ValueToGive';
      const headingValueToGive = document.querySelector('.init_a_headingValueToGive');
      const valueToGiveItem = document.querySelectorAll('.init_a_valueToGiveItem');
      this.initAnimation.heading(headingValueToGive, isOurValueToGive);
      this.initAnimation.list(valueToGiveItem);
      unobserve();
    }
  };

  render() {
    const ServicesQuery = graphql`
      {
        illustration: file(relativePath: { eq: "illustration/illustration02.png" }){
          childImageSharp {
            fluid(maxWidth: 835, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        strategy: file(relativePath: { eq: "illustration/icon_strategy.png" }){
          childImageSharp {
            fluid(maxWidth: 280, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        contents: file(relativePath: { eq: "illustration/icon_contents.png" }){
          childImageSharp {
            fluid(maxWidth: 280, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        design: file(relativePath: { eq: "illustration/icon_design.png" }){
          childImageSharp {
            fluid(maxWidth: 280, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        engineering: file(relativePath: { eq: "illustration/icon_engineering.png" }){
          childImageSharp {
            fluid(maxWidth: 280, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        mediagrowth: file(relativePath: { eq: "illustration/icon_mediagrowth.png" }){
          childImageSharp {
            fluid(maxWidth: 280, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        anyother: file(relativePath: { eq: "illustration/icon_anyother.png" }){
          childImageSharp {
            fluid(maxWidth: 280, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
      }
    `;

    const transition = {
      InitTransition,
      animation: () => {
        this.child.current.exitAnimation(transition.InitTransition.length);
      },
    };

    const options = {
      root: null,
      rootMargin: '-50% 0px',
      threshold: 0,
    };

    // const optionsValueToGive = {
    //   root: null,
    //   rootMargin: '-50px',
    //   threshold: 0,
    // };

    return (
      <StaticQuery
        query={ServicesQuery}
        render={(data) => (
          <Layout>
            <Transition ref={this.child}>
              <Header transition={transition} current="services" />
              <main className={`services ${Style.services}`}>
                <section className={`top ${Style.top}`}>
                  <div className={Style.left}>
                    <h1 className="heading">
                      <span className="text">Services<br className="spTabHDisBlock" /> & Value</span>
                      <span className="clip init_a_headingServices">Services<br className="spTabHDisBlock" /> & Value</span>
                    </h1>
                    <p className={`intro init_a_introServices ${Style.intro}`}>バンクトゥは「編集」という職能をベースに、戦略設計・デザイン・エンジニアリングをカバーするクリエイティブ・ファームです。ものの見方を変えたり、常識を疑い、世の中の「当たり前」を変えていきます。</p>
                  </div>
                  <figure className={Style.illustration}>
                    <Img
                      className={Style.illustrationImg}
                      fluid={data.illustration.childImageSharp.fluid}
                      alt="Services & Value"
                    />
                  </figure>
                </section>
                <Observer {...options} onChange={this.ourServices}>
                  <section className={`sectionContent sectionContentFirst ${Style.ourServices}`}>
                    <div className="scroll scrollTop" />
                    <div className={`inner ${Style.inner}`}>
                      <h2 className="heading">
                        <span className="text textOurServices">Our <br className="spDisBlock" />Services</span>
                        <span className="clip clipOurServices init_a_headingOurServices">Our <br className="spDisBlock" />Services</span>
                      </h2>
                      <ul className={Style.ourServicesList}>
                        <li className={`init_a_ourServicesItem ${Style.ourServicesItem}`}>
                          <TransitionLink to="/services/strategy" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                            <figure className={Style.itemIllustration}>
                              <Img
                                fluid={data.strategy.childImageSharp.fluid}
                                alt="Strategy"
                              />
                            </figure>
                            <h3>Strategy</h3>
                            <p className={Style.ourServicesItemText}>課題や価値発見のリサーチ・分析に基づき、ユーザーの行動に合わせたメディア施策を設計し、アウトプットまでの一貫した実行が可能です。コンテンツ制作の現場で培った観察眼を活かして、ユーザーに届く戦略を設計します。</p>
                            <div className={`viewMore ${Style.viewMore}`}>
                              <svg
                                className="arrow"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 125 20.4"
                              >
                                <line x1="0" y1="10.2" x2="200" y2="10.2" />
                                <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />
                              </svg>
                            </div>
                          </TransitionLink>
                        </li>
                        <li className={`init_a_ourServicesItem ${Style.ourServicesItem}`}>
                          <TransitionLink to="/services/contents" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                            <figure className={Style.itemIllustration}>
                              <Img
                                fluid={data.contents.childImageSharp.fluid}
                                alt="Contents"
                              />
                            </figure>
                            <h3>Contents</h3>
                            <p className={Style.ourServicesItemText}>編集という職能を独自に定義し、良質なコンテンツを制作します。ユーザーにとって、本質的に価値のある情報とは何か？ メディア運営や取材経験の豊富な編集者が、テキストや映像を駆使し、印象に強く残る体験を届けます。</p>
                            <div className={`viewMore ${Style.viewMore}`}>
                              <svg
                                className="arrow"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 125 20.4"
                              >
                                <line x1="0" y1="10.2" x2="200" y2="10.2" />
                                <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />
                              </svg>
                            </div>
                          </TransitionLink>
                        </li>
                        <li className={`init_a_ourServicesItem ${Style.ourServicesItem}`}>
                          <TransitionLink to="/services/design" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                            <figure className={Style.itemIllustration}>
                              <Img
                                fluid={data.design.childImageSharp.fluid}
                                alt="Design"
                              />
                            </figure>
                            <h3>Design</h3>
                            <p className={Style.ourServicesItemText}>雑誌や書籍、ポスター等の紙媒体から、大小様々な規模のウェブやアプリまで、施策に合わせた最適なデザインを提案します。提供された情報をそのまま形にするのではなく、再構築した上で、デザインアイデアを生み出します。</p>
                            <div className={`viewMore ${Style.viewMore}`}>
                              <svg
                                className="arrow"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 125 20.4"
                              >
                                <line x1="0" y1="10.2" x2="200" y2="10.2" />
                                <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />
                              </svg>
                            </div>
                          </TransitionLink>
                        </li>
                        <li className={`init_a_ourServicesItem ${Style.ourServicesItem}`}>
                          <TransitionLink to="/services/engineering" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                            <figure className={Style.itemIllustration}>
                              <Img
                                fluid={data.engineering.childImageSharp.fluid}
                                alt="Engineering"
                              />
                            </figure>
                            <h3>Engineering</h3>
                            <p className={Style.ourServicesItemText}>エンジニアは「目に見えない」情報設計を担っています。ロード時間やアクセシビリティなど、ユーザーのストレスに配慮したり、レスポンシブやPWAなど変化の激しいウェブ環境に対応するべく、R&Dに力を入れています。</p>
                            <div className={`viewMore ${Style.viewMore}`}>
                              <svg
                                className="arrow"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 125 20.4"
                              >
                                <line x1="0" y1="10.2" x2="200" y2="10.2" />
                                <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />
                              </svg>
                            </div>
                          </TransitionLink>
                        </li>
                        <li className={`init_a_ourServicesItem ${Style.ourServicesItem}`}>
                          <TransitionLink to="/services/mediagrowth" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                            <figure className={Style.itemIllustration}>
                              <Img
                                fluid={data.mediagrowth.childImageSharp.fluid}
                                alt="Mediagrowth"
                              />
                            </figure>
                            <h3>MediaGrowth</h3>
                            <p className={Style.ourServicesItemText}>グロースハックの思考や手法に基づき、ウェブサイトの定期的なデータ解析や運用サポートなどを行い、継続的なチェックとプロダクトの改善提案を実行します。メディアの成長をスピーディに促し、ユーザーの満足度を最大化します。</p>
                            <div className={`viewMore ${Style.viewMore}`}>
                              <svg
                                className="arrow"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 125 20.4"
                              >
                                <line x1="0" y1="10.2" x2="200" y2="10.2" />
                                <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />
                              </svg>
                            </div>
                          </TransitionLink>
                        </li>
                        <li className={`init_a_ourServicesItem ${Style.ourServicesItem}`}>
                          <figure className={Style.itemIllustration}>
                            <Img
                              fluid={data.anyother.childImageSharp.fluid}
                              alt="Anyother"
                            />
                          </figure>
                          <h3>Anyother</h3>
                          <p className={Style.ourServicesItemText}>戦略設計からコンテンツやデザインまで、幅広い領域をカバーする総合的なクリエイティブ・ファームとして、媒体を横断する包括的・一貫的な施策提案や実行が可能です。プロジェクトを一元管理してコストダウンが可能です。</p>
                        </li>
                      </ul>
                    </div>
                  </section>
                </Observer>
                {/*<Observer {...optionsValueToGive} onChange={this.valueToGive}>*/}
                {/*  <section className={`sectionContent ${Style.valueToGive}`}>*/}
                {/*    <div className="inner">*/}
                {/*      <h2 className="heading">*/}
                {/*        <span className="text textValueToGive">Value to <br className="spDisBlock" /> give</span>*/}
                {/*        <span className="clip clipValueToGive init_a_headingValueToGive">Value to <br className="spDisBlock" /> give</span>*/}
                {/*      </h2>*/}
                {/*      <ul className={Style.valueToGiveList}>*/}
                {/*        <li className={`init_a_valueToGiveItem ${Style.valueToGiveItem}`}>*/}
                {/*          <h4>スキルアップ研修</h4>*/}
                {/*          <p>碁盤の目の街を縫うように、路地がひしめく京都。その数、約13,000本とも。そこには観光とは無縁の、京都の素顔が広がっています。Googleでさえ、知らない世界へ。なんでもないような路上に目を向け、丁寧に観察するように京都を捉え直してみる。</p>*/}
                {/*        </li>*/}
                {/*        <li className={`init_a_valueToGiveItem ${Style.valueToGiveItem}`}>*/}
                {/*          <h4>スキルアップ研修</h4>*/}
                {/*          <p>碁盤の目の街を縫うように、路地がひしめく京都。その数、約13,000本とも。そこには観光とは無縁の、京都の素顔が広がっています。Googleでさえ、知らない世界へ。なんでもないような路上に目を向け、丁寧に観察するように京都を捉え直してみる。</p>*/}
                {/*        </li>*/}
                {/*        <li className={`init_a_valueToGiveItem ${Style.valueToGiveItem}`}>*/}
                {/*          <h4>スキルアップ研修</h4>*/}
                {/*          <p>碁盤の目の街を縫うように、路地がひしめく京都。その数、約13,000本とも。そこには観光とは無縁の、京都の素顔が広がっています。Googleでさえ、知らない世界へ。なんでもないような路上に目を向け、丁寧に観察するように京都を捉え直してみる。</p>*/}
                {/*        </li>*/}
                {/*        <li className={`init_a_valueToGiveItem ${Style.valueToGiveItem}`}>*/}
                {/*          <h4>スキルアップ研修</h4>*/}
                {/*          <p>碁盤の目の街を縫うように、路地がひしめく京都。その数、約13,000本とも。そこには観光とは無縁の、京都の素顔が広がっています。Googleでさえ、知らない世界へ。なんでもないような路上に目を向け、丁寧に観察するように京都を捉え直してみる。</p>*/}
                {/*        </li>*/}
                {/*        <li className={`init_a_valueToGiveItem ${Style.valueToGiveItem}`}>*/}
                {/*          <h4>スキルアップ研修</h4>*/}
                {/*          <p>碁盤の目の街を縫うように、路地がひしめく京都。その数、約13,000本とも。そこには観光とは無縁の、京都の素顔が広がっています。Googleでさえ、知らない世界へ。なんでもないような路上に目を向け、丁寧に観察するように京都を捉え直してみる。</p>*/}
                {/*        </li>*/}
                {/*        <li className={`init_a_valueToGiveItem ${Style.valueToGiveItem}`}>*/}
                {/*          <h4>スキルアップ研修</h4>*/}
                {/*          <p>碁盤の目の街を縫うように、路地がひしめく京都。その数、約13,000本とも。そこには観光とは無縁の、京都の素顔が広がっています。Googleでさえ、知らない世界へ。なんでもないような路上に目を向け、丁寧に観察するように京都を捉え直してみる。</p>*/}
                {/*        </li>*/}
                {/*      </ul>*/}
                {/*    </div>*/}
                {/*  </section>*/}
                {/*</Observer>*/}
              </main>
              <Footer transition={transition} />
            </Transition>
          </Layout>
        )}
      />
    );
  }
}

export default Services;
